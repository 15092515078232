<template>
  <div>
    <v-row class="mb-4">
      <v-col
        v-for="item in items"
        :key="item.title"
        cols="12"
        sm="12"
        md="4"
        lg="4"
      >
        <v-card
          class="px-4 rounded-lg"
          :color="item.background"
        >
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="card-info">
              <h1 class="white--text font-weight-bold">
                {{ item.total }}
              </h1>
              <h2 class="white--text font-weight-light">
                {{ item.title }}
              </h2>
            </div>
            <div class="card-img">
              <!-- <img
                src="../../../assets/images/3d-characters/budi.png"
                :width="item.imgWidth"
                :height="item.imgHeight"
                alt=""
              > -->
              <img
                :src="item.src"
                :width="item.imgWidth"
                :height="item.imgHeight"
                alt=""
              >
            </div>
            <img
              :src="item.shapes"
              alt=""
              class=""
              style="position: absolute;bottom: 0;left: 0;right: 0;width: 100%;height: 80px;z-index: 0;"
            >
            <!-- <img
              src="../../../assets/images/shapes/3.png"
              alt=""
              class=""
              style="position: absolute;bottom: 0;left: 0;right: 0;width: 100%;height: 80px;z-index: 0;"
            > -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="title mb-4">
      <h4>Riwayat Terakhir</h4>
    </div>
    <v-row>
      <v-col
        v-for="history in histories"
        :key="history.title"
        cols="12"
        md="2"
      >
        <v-card>
          <v-card-title>
            <h4>{{ history.title }}</h4>
          </v-card-title>
          <v-card-text class="text-center">
            <div class="mb-4">
              <v-progress-circular
                :value="20"
                :size="100"
                :width="15"
                :color="history.color"
              ></v-progress-circular>
            </div>
            <div class="">
              <h4 class="black--text font-weight-bold">
                {{ history.subject }}
              </h4>
            </div>
            <div class="">
              <span class="font-weight-light">{{ history.last_seen }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import siti from '@/assets/images/3d-characters/siti.png'
import usup from '@/assets/images/3d-characters/usup.png'
import budi from '@/assets/images/3d-characters/budi.png'
import shape1 from '@/assets/images/shapes/1.png'
import shape2 from '@/assets/images/shapes/2.png'
import shape3 from '@/assets/images/shapes/3.png'

export default {
  name: 'DashboardSchool',
  data() {
    return {
      items: [
        {
          total: 12,
          title: 'CBT',
          background: '#42C1EC',
          // src: '../../../assets/images/3d-characters/siti.png',
          src: siti,
          imgWidth: '100%',
          imgHeight: 120,
          shapes: shape1,
        },
        {
          total: 8,
          title: 'E-Learning',
          background: '#F2AB39',
          src: budi,
          imgWidth: '100%',
          imgHeight: 120,
          shapes: shape3,
        },
        {
          total: 16,
          title: 'Bank Soal',
          background: '#93278F',
          src: usup,
          imgWidth: '100%',
          imgHeight: 120,
          shapes: shape2,
        },
      ],
      histories: [
        {
          title: 'E-Learning',
          total: 70,
          subject: 'Bahasa Indonesia',
          persentase: '70%',
          last_seen: '10 Menit yang lalu',
          color: 'primary',
        },
        {
          title: 'Bank Soal',
          total: 50,
          subject: 'Matematika',
          last_seen: '1 Jam yang lalu',
          color: 'success',
        },
      ],
    }
  },
}
</script>

<style>
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
</style>
